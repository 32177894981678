import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import {
  CaptchaError,
  RenderParams,
  SmartCaptcha,
  YandexSmartCaptcha
} from './yandex-smart-captcha.types';
import { filter, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class YandexSmartCaptchaService {
  scriptStatus = new BehaviorSubject<boolean>(false);
  public smartCaptcha?: SmartCaptcha;

  private renderer: Renderer2;
  private readonly scriptUrl: string =
    'https://smartcaptcha.yandexcloud.net/captcha.js';

  constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.loadJsScript();
  }

  public renderCaptcha = (
    component: YandexSmartCaptcha,
    element: HTMLElement | string,
    params: RenderParams
  ): Observable<number> =>
    this.scriptStatus.pipe(
      filter(loaded => loaded),
      take(1),
      map(() => {
        const widgetId = this.smartCaptcha.render(element, params);
        this.subscribe(component, widgetId);
        return widgetId;
      })
    );
  /**
   * Показ состояния ошибки виджета
   *
   * @param widgetId id виджета капчи
   */
  public destroy(widgetId: number): void {
    this.smartCaptcha?.destroy(widgetId);
  }
  /**
   * Показ состояния ошибки виджета
   *
   * @param widgetId id виджета капчи
   */
  public reset(widgetId: number): void {
    this.smartCaptcha?.reset(widgetId);
  }
  /**
   * Показ состояния ошибки виджета
   *
   * @param widgetId id виджета капчи
   */
  public getResponse(widgetId: number): string {
    return this.smartCaptcha ? this.smartCaptcha.getResponse(widgetId) : '';
  }
  /**
   * Переключение статуса виджета
   *
   * @param value статус прохождения
   */
  private setStatus(value: boolean): void {
    this.scriptStatus.next(value);
  }
  /**
   * Показ состояния ошибки виджета
   *
   * @param widgetId id виджета капчи
   */
  private loadJsScript = (): HTMLScriptElement => {
    const script = this.renderer.createElement('script') as HTMLScriptElement;
    script.type = 'text/javascript';
    script.src = this.scriptUrl;
    script.async = true;
    script.defer = true;
    script.onload = () => {
      this.smartCaptcha = window.smartCaptcha;
      this.setStatus(true);
    };
    script.onerror = () => {
      throw new Error('Could not load the Yandex SmartCaptcha.');
    };
    this.renderer.appendChild(this.document.head, script);
    return script;
  };
  /**
   * Показ состояния ошибки виджета
   *
   * @param component компонент виджета
   * @param widgetId id виджета капчи
   */
  private subscribe = (component: YandexSmartCaptcha, widgetId: number) => {
    if (this.smartCaptcha) {
      this.smartCaptcha.subscribe(widgetId, 'challenge-visible', () =>
        component.onChallengeVisible.emit()
      );
      this.smartCaptcha.subscribe(widgetId, 'challenge-hidden', () =>
        component.onChallengeHidden.emit()
      );
      this.smartCaptcha.subscribe(widgetId, 'network-error', () =>
        component.onNetworkError.emit()
      );
      this.smartCaptcha.subscribe(
        widgetId,
        'javascript-error',
        (error: CaptchaError) => component.onJavaScriptError.emit(error)
      );
      this.smartCaptcha.subscribe(widgetId, 'success', (token: string) =>
        component.onSuccess.emit(token)
      );
      this.smartCaptcha.subscribe(widgetId, 'token-expired', () =>
        component.onTokenExpired.emit()
      );
    }
  };
}
