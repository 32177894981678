import {
  Customer,
  TradingOrganization,
  PaymentOption,
  Product,
  Document,
  Serializable
} from 'src/app/core/models';
import { isNull } from 'lodash';
import { TechStateProcessing } from '@core/models/TaxFreeCheque/TechStateProcessing';

export enum PaymentPermission {
  WITHOUT_CHECK = 'WITHOUT_CHECK',
  SUSPENDED = 'SUSPENDED',
  APPROVED = 'APPROVED'
}

export enum AccountOperatorChecksSearchableFields {
  tradingOrganizationName = 'tradingOrganizationName',
  customerSurname = 'customerSurname',
  passportNumber = 'passportNumber',
  chequeIdNumber = 'chequeIdNumber'
}

export interface IPaymentStateHistory {
  history: IPaymentHistory[];
  startedAt: Date;
  state: IPaymentState;
  lastPaymentStatus: string;
}

export interface IPaymentHistory {
  state: string;
  ts: Date;
}

export interface IPaymentState {
  assignedBy: string;
  error: string;
  gatewayName: string;
  status: string;
}

interface IRepeatedCommodity {
  chequeId: string;
  name: string;
}

export class TaxFreeCheque extends Serializable {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  lastUpdatedBy: string;
  active: boolean;
  status: string;
  tradingOrganization: TradingOrganization;
  customer: Customer;
  paymentDetails: PaymentOption;
  paymentDate: string;
  paymentPermission: PaymentPermission;
  paymentMethod: string;
  document: Document;
  commodities: Product[];
  repeatedCommodities: IRepeatedCommodity[] | null;
  taxRefundSum?: number;
  taxBaseSum?: number;
  taxBaseSumEx?: number;
  commoditiesTaxRefundSumEx?: number;
  refundCurrency?: any;
  cardReceiptMismatch?: string;
  cardExpired?: string;
  ortDigitalSignature?: boolean;
  digitalSignatureError?: string;
  awaitingManualPayment?: boolean;
  commoditiesTaxRefundSum?: number;
  maxExportDate?: Date;
  processing?: TechStateProcessing;
  comment?: string;
  secondaryFormConfirmationInOrt?: boolean;
  paymentStateHistory: IPaymentStateHistory;
  constructor(json?: any) {
    super(json);
    this.calcTaxRefundSum();
    this.calcTaxBaseSum();
    this.calcTaxBaseSumEx();
    this.castDates();
    if (isNull(this.commoditiesTaxRefundSumEx)) {
      this.calcTaxRefundSumEx();
    }
  }

  /**
   * Расчет суммы возврата
   *
   * @returns http параметры
   */
  private calcTaxRefundSum() {
    this.taxRefundSum = 0;
    for (const product of this.commodities) {
      this.taxRefundSum += product.taxRefundSum;
    }
  }
  /**
   * Расчет суммы возврата в валюте покупателя
   *
   * @returns http параметры
   */
  private calcTaxRefundSumEx() {
    this.commoditiesTaxRefundSumEx = 0;
    for (const product of this.commodities) {
      this.commoditiesTaxRefundSumEx += product.taxRefundSumEx;
    }
  }

  /**
   * Преобразование дат из строки в объект Date
   */
  private castDates() {
    const castCreatedAt = new Date(this.createdAt);
    const castUpdatedAt = new Date(this.updatedAt);
    this.createdAt = castCreatedAt;
    this.updatedAt = castUpdatedAt;
  }

  /**
   * Расчет суммы Налоговой базы
   */
  private calcTaxBaseSum() {
    this.taxBaseSum = 0;
    for (const product of this.commodities) {
      this.taxBaseSum += product.cost;
    }
  }

  /**
   * Расчет суммы Налоговой базы в валюте покупателя
   */
  private calcTaxBaseSumEx() {
    this.taxBaseSumEx = 0;
    for (const product of this.commodities) {
      this.taxBaseSumEx += product.costEx ? product.costEx : 0;
    }
  }
}
