/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-len */
/* eslint-disable id-denylist */
// roles

/* eslint-disable max-len */
export const PORTAL_ADMIN = 'PORTAL_ADMIN';
export const REPORT_ADMIN = 'REPORT_ADMIN';
export const PARTNER = 'PARTNER';
export const CUSTOMER = 'USER';
export const OPERATOR = 'OPERATOR';
export const PAYMENT_OPERATOR = 'PAYMENT_OPERATOR';
export const ALL_LABEL = 'Все';
export const ALL_TEMPLATE_LABEL = 'Все по загруженному файлу';

export const REFERRAL_CODE_ID = 'referral_code';

export const AUTH_USER_ID_ITEM_NAME = 'authUserId';
export const TAX_FREE_CHEQUES_FILTER_ID = 'taxFreeChequesFilterId';
export const TAX_FREE_CHEQUES_ADMIN_FILTER_ID = 'taxFreeChequesAdminFilterId';
export const TAX_FREE_CHEQUES_SORT_ID = 'taxFreeChequesSortId';
export const TAX_FREE_PARTNER_OFFER_FILTER_ID = 'taxFreePartnerOfferFilterId';
export const CHEQUES_REPORT_FILTERS = 'chequesReportFilters';
export const GOODS_REPORT_FILTERS = 'goodsReportFilters';
export const USERS_REPORT_FILTERS = 'usersReportFilters';
export const CHECKPOINTS_TYPE_FILTER = 'checkpointsTypeFilter';
export const TAX_FREE_OFFER_FILTERS_ID = 'taxFreeOffersFilterId';

export const ACCEPTED_TYPES_FILE = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/jpg',
];
export const DEAFULT_UPLOAD_LIMIT = 10 * 1024 * 1024;
export const MY_CUSTOM_FORMATS = {
  parseInput: 'DD/MM/YYYY',
  fullPickerInput: 'DD.MM.YYYY HH:mm:ss',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: 'HH:mm:ss',
  monthYearLabel: 'MMMM',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM-YYYY',
};

// Константы для Яндекс Метрики, яндекс метрика подключается пакетом ng-yandex-metrika,
// версию пакета менять только если поменяется версия Angular
export const enum YANDEX_METRIKS_EVENTS {
  form_complete_writeus = 'form_complete_writeus',
  form_complete_trackidcheck = 'form_complete_trackidcheck',
  form_complete_tracknumber = 'form_complete_tracknumber',
  form_complete_trackphone = 'form_complete_trackphone',
  form_complete_trackpassport = 'form_complete_trackpassport',
  form_complete_joinus = 'form_complete_joinus',
  form_complete_aboutus = 'form_complete_aboutus',
  form_complete_login = 'form_complete_login',
}

export const enum REGISTRED_USER_LOGIN_TYPE {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

export const MY_FORMATS_MATERIAL = {
  parse: {
    dateInput: 'MMMM YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMMM YYYY',
  },
};
export const FOR_TRAVELLERS_RU = 'Для путешественников';
export const FOR_TRAVELLERS_UZ = 'Sayohatchilar uchun';
export const FOR_TRAVELLERS_EN = 'For travelers';
export const FOR_TRAVELLERS_ZH = '对于游客';

export const instructionsRU = 'Условия и правила Tax Free';
export const instructionsEN = 'Terms and conditions of Tax Free';
export const instructionsUZ = 'Tax Free shartlari va qoidalari';
export const instructionsZH = 'Tax Free的条件和规定';

export const calculatorUZ = 'Tax Free kalkulyatori';
export const calculatorRU = 'Калькулятор Tax Free';
export const calculatorZH = 'Tax Free 计算器';
export const calculatorEN = 'Tax Free Calculator';

export const widgetUZ = 'Подключение виджета';
export const widgetRU = 'Подключение виджета';
export const widgetZH = 'Подключение виджета';
export const widgetEN = 'Подключение виджета';

export const STORES_EN = 'Stores';

export const CUSTOM_CHECKPOINT_EN = 'Customs checkpoint';
export const CUSTOM_CHECKPOINT_RU = 'Пункты пропуска';

export const REFUND_STATUS_EN = 'VAT refund status';

export const FOR_BUSINESS_EN = 'For business';
export const FOR_BUSINESS_RU = 'Для бизнеса';
export const FOR_BUSINESS_UZ = 'Biznes uchun';
export const FOR_BUSINESS_ZH = '商务对商务';

export const PARTICIPATION_CONDITIONS_EN = 'Participation conditions';

export const BECOME_A_PARTNER_EN = 'Become a partner';

export const PRIVILEGE_EN = 'Privilege';
export const CONNECTION_EN = 'Connect an online store';

export const ABOUT_US_EN = 'About us';
export const ABOUT_US_RU = 'О нас';
export const ABOUT_US_UZ = 'Biz haqimizda';
export const ABOUT_US_ZH = '关于我们';

export const companyRU = 'Наша компания';
export const companyEN = 'Our company';
export const companyUZ = 'Kompaniyamiz';
export const companyZH = '我们的公司';

export const LEGAL_INFO_EN = 'Legal Information';
export const LEGAL_INFO_RU = 'Правовая информация';
export const LEGAL_INFO_UZ = 'Huquqiy axborot';
export const LEGAL_INFO_ZH = '法律信息';

export const contactsTitleEN = 'Contacts';
export const contactsEN = 'Our contacts';
export const contactsZH = '我们的联系方式';
export const contactsRU = 'Наши контакты';
export const contactsUZ = 'Bizning aloqa ma\'lumotlarimiz';

export const FAQ_EN = 'FAQs';
export const FAQ_LANG = 'FAQ';

export const COUNTRY = 'COUNTRY';
export const BY_COUNTRY = 'По странам';

export const MULITIPLE_TYPE = 'multiple';
export const BOUND_TYPE = 'bound';
export const SELECT_TYPE = 'select';

export const PASSPORT = 'passport';
export const PASSPORT_EDIT_TITLE = {
  en: 'Passport',
  ru: 'Паспорт',
  uz: 'Pasporti',
  zh: '护照',
};

export const REFUND_OPTIONS = 'refund-options';
export const REFUND_OPTIONS_TITLE = {
  en: 'Payment details',
  ru: 'Платежные данные',
  uz: 'To\'lov ma\'lumotlari',
  zh: '支付信息',
};

export const CONTACT_DETAILS = 'contact-details';
export const CONTACT_DETAILS_TITLE = {
  en: 'Contact details',
  ru: 'Контактная информация',
  uz: 'Bog\'lanish ma\'lumotlari',
  zh: '联系方式',
};

export const ACCOUNT_SETTINGS_EN = 'Account Settings';
export const ACCOUNT_SETTINGS_RU = 'Настройки аккаунта';
export const ACCOUNT_SETTINGS_ZH = '账户设置';
export const ACCOUNT_SETTINGS = 'account-settings';
export const CUSTOMER_PROFILE_SECTION_TITLES = {
  passport: {
    routePath: PASSPORT,
    ...PASSPORT_EDIT_TITLE,
  },
  'refund-options': {
    routePath: REFUND_OPTIONS,
    ...REFUND_OPTIONS_TITLE,
  },
  'contact-details': {
    routePath: CONTACT_DETAILS,
    ...CONTACT_DETAILS_TITLE,
  },
  'account-settings': {
    en: ACCOUNT_SETTINGS_EN,
    ru: ACCOUNT_SETTINGS_RU,
    uz: ACCOUNT_SETTINGS_EN,
    zh: ACCOUNT_SETTINGS_ZH,
    routePath: ACCOUNT_SETTINGS,
  },
};

export const HEADER_LINKS = [
  {
    title: FOR_TRAVELLERS_EN,
    lang: {
      ru: FOR_TRAVELLERS_RU,
      uz: FOR_TRAVELLERS_UZ,
      en: FOR_TRAVELLERS_EN,
      zh: FOR_TRAVELLERS_ZH,
    },
    pathMatch: 'full',
    routerLink: '/traveller/checkpoints',
    children: [
      {
        title: STORES_EN,
        routerLink: '/traveller/shops',
        lang: {
          ru: 'Магазины',
          uz: 'Do\'konlar',
          en: STORES_EN,
          zh: '商店',
        },
      },
      {
        title: 'Special offers',
        routerLink: '/traveller/offers',
        lang: {
          ru: 'Специальные предложения',
          uz: 'Maxsus takliflar',
          en: 'Special offers',
          zh: '优惠促销',
        },
      },
      {
        title: 'How to Shop Tax Free',
        routerLink: '/traveller/instructions',
        lang: {
          ru: instructionsRU,
          uz: instructionsUZ,
          en: instructionsEN,
          zh: instructionsZH,
        },
      },
      {
        title: 'Refund Calculator',
        routerLink: '/traveller/taxcalculator',
        lang: {
          ru: calculatorRU,
          uz: calculatorUZ,
          en: calculatorEN,
          zh: calculatorZH,
        },
      },
      {
        title: CUSTOM_CHECKPOINT_EN,
        routerLink: '/traveller/checkpoints',
        lang: {
          ru: CUSTOM_CHECKPOINT_RU,
          uz: 'Nazorati punktlari',
          en: CUSTOM_CHECKPOINT_EN,
          zh: '海关检查站',
        },
      },
      {
        title: REFUND_STATUS_EN,
        routerLink: '/traveller/refundtracker/search-by-id',
        lang: {
          ru: 'Статус возмещения НДС',
          uz: 'QQS-ni qaytarish holati',
          en: REFUND_STATUS_EN,
          zh: '增值税退税状态',
        },
      },
    ],
  },
  {
    title: FOR_BUSINESS_EN,
    routerLink: '/business/cash-registers',
    lang: {
      ru: FOR_BUSINESS_RU,
      uz: FOR_BUSINESS_UZ,
      en: FOR_BUSINESS_EN,
      zh: FOR_BUSINESS_ZH,
    },
    children: [
      {
        title: PARTICIPATION_CONDITIONS_EN,
        routerLink: '/business/cash-registers',
        lang: {
          ru: 'Условия подключения',
          uz: 'Ulanish shartlari',
          en: PARTICIPATION_CONDITIONS_EN,
          zh: '参加条件',
        },
      },
      {
        title: BECOME_A_PARTNER_EN,
        routerLink: '/business/join',
        lang: {
          ru: 'Стать партнером',
          uz: 'Hamkor bo\'lish',
          en: BECOME_A_PARTNER_EN,
          zh: '成为合作伙伴',
        },
      },
      {
        title: PRIVILEGE_EN,
        routerLink: '/business/cooperation',
        lang: {
          ru: 'Преимущества',
          uz: 'Afzalliklari',
          en: PRIVILEGE_EN,
          zh: '优势',
        },
      },
      {
        title: CONNECTION_EN,
        routerLink: '/business/self-connection',
        lang: {
          ru: 'Подключить интернет-магазин',
          uz: CONNECTION_EN,
          en: CONNECTION_EN,
          zh: CONNECTION_EN,
        },
      },
      {
        title: widgetEN,
        routerLink: '/business/widget-info',
        lang: {
          ru: widgetRU,
          uz: widgetUZ,
          en: widgetEN,
          zh: widgetZH,
        },
      },
    ],
  },
  {
    title: ABOUT_US_EN,
    routerLink: '/about',

    lang: {
      ru: ABOUT_US_RU,
      uz: ABOUT_US_UZ,
      en: ABOUT_US_EN,
      zh: ABOUT_US_ZH,
    },
    children: [
      {
        title: ABOUT_US_EN,
        routerLink: '/about/our-company',
        lang: {
          ru: companyRU,
          uz: companyUZ,
          en: companyEN,
          zh: companyZH,
        },
      },
      {
        title: LEGAL_INFO_EN,
        routerLink: '/about/terms',
        lang: {
          ru: LEGAL_INFO_RU,
          uz: LEGAL_INFO_UZ,
          en: LEGAL_INFO_EN,
          zh: LEGAL_INFO_ZH,
        },
      },
      {
        title: contactsTitleEN,
        routerLink: '/about/contacts',
        lang: {
          ru: 'Контакты',
          uz: 'Aloqalar',
          en: contactsTitleEN,
          zh: CONTACT_DETAILS_TITLE.zh,
        },
      },
      {
        title: FAQ_EN,
        routerLink: '/about/faq',
        lang: {
          ru: FAQ_LANG,
          uz: FAQ_LANG,
          en: FAQ_LANG,
          zh: FAQ_LANG,
        },
      },
    ],
  },
];

export const TAX_FREE_CHEQUE_TYPE = 'tax-free-cheque';
export const COMMODITY_TYPE = 'commodity';
export const USER_TYPE = 'user';

export const reportTypes = [
  { code: TAX_FREE_CHEQUE_TYPE, name: 'Отчет по чекам Tax Free' },
  { code: COMMODITY_TYPE, name: 'Отчет по товарам из чеков Tax Free' },
  { code: USER_TYPE, name: 'Отчет по пользователям' },
];
export const APPROVED = 'APPROVED';
export const SUSPENDED = 'SUSPENDED';
export const WITHOUT_CHECK = 'WITHOUT_CHECK';
export const APPROVED_RU = 'Подтверждено';
export const SUSPENDED_RU = 'Приостановлено';
export const WITHOUT_CHECK_RU = 'Без проверки';

export const paymentPermissions = [
  { code: APPROVED, name: APPROVED_RU },
  { code: SUSPENDED, name: SUSPENDED_RU },
  { code: WITHOUT_CHECK, name: WITHOUT_CHECK_RU },
];

export const COUNT = 'COUNT';
export const COST_SUM = 'COST_SUM';

export const reportOneIndexes = [
  { code: COUNT, name: 'Количество чеков Tax Free' },
  { code: COST_SUM, name: 'Сумма покупок (в рублях)' },
  { code: 'COST_SUM_EX', name: 'Сумма покупок (в валюте выплаты компенсации)' },
  { code: 'REFUND_SUM', name: 'Компенсация (в рублях)' },
  { code: 'REFUND_SUM_EX', name: 'Компенсация (в валюте выплаты компенсации)' },
  { code: 'COMMISSION_SUM', name: 'Сервисный сбор (в рублях)' },
  {
    code: 'COMMISSION_SUM_EX',
    name: 'Сервисный сбор (в валюте выплаты компенсации)',
  },
  {
    code: 'FTS_VALIDATION_FAILED_PERCENT',
    name: 'Процент количества невалидных чеков по итогам валидации',
  },
  {
    code: 'EXPORT_DATE_EXPIRED_PERCENT',
    name: 'Процент количества невалидных чеков по срокам вывоза',
  },
  {
    code: 'FTS_VALIDATION_FAILED_SUM_PERCENT',
    name: 'Процент суммы покупок невалидных чеков по итогам валидации',
  },
  {
    code: 'EXPORT_DATE_EXPIRED_SUM_PERCENT',
    name: 'Процент суммы покупок невалидных чеков по срокам вывоза',
  },
  {
    code: 'FNS_CHECKING_FAILED_SUM_PERCENT',
    name: 'Процент суммы покупок невалидных чеков по сверке с данными ФНС',
  },
  {
    code: 'PAYMENT_REFUND_DATE_EXPIRED_PERCENT',
    name: 'Процент количества невалидных чеков по срокам выплаты',
  },
  {
    code: 'PAYMENT_REFUND_DATE_EXPIRED_SUM_PERCENT',
    name: 'Процент суммы покупок невалидных чеков по срокам выплаты',
  },
];

export const UNDELIVERED_PERCENT = 'UNDELIVERED_PERCENT';
export const reportTwoIndexes = [
  { code: COUNT, name: 'Количество товаров' },
  {
    code: UNDELIVERED_PERCENT,
    name: 'Процент невывезенного количества товаров',
  },
  { code: COST_SUM, name: 'Сумма товаров' },
  {
    code: 'UNDELIVERED_SUM_PERCENT',
    name: 'Процент невывезенной суммы товаров',
  },
];

export const reportThreeIndexes = [
  {
    code: 'COUNT_MOBILE',
    name: 'Количество уникальных активных пользователей мобильного приложения',
  },
  {
    code: 'COUNT_PORTAL',
    name: 'Количество уникальных активных пользователей личного кабинета покупателя',
  },
];

export const ACTUAL_VERSION = 'ACTUAL_VERSION';
export const ACTUAL_VERSION_NAME = 'По признаку актуальной версии';
export const YEAR = 'YEAR';
export const YEAR_LABEL = 'По годам';
export const QUARTER = 'QUARTER';
export const QUARTER_LABEL = 'По кварталам';
export const MONTH = 'MONTH';
export const MONTH_LABEL = 'По месяцам';
export const DAY = 'DAY';
export const DAY_LABEL = 'По дням';
export const TRADING_ORGANIZATION = 'TRADING_ORGANIZATION';
export const TRADING_ORGANIZATION_LABEL = 'По ОРТ';
export const CHEQUE_STATUS = 'CHEQUE_STATUS';
export const CHEQUE_STATUS_LABEL = 'По статусам';
export const CITIZENSHIP = 'CITIZENSHIP';
export const CITIZENSHIP_LABEL = 'По гражданству покупателей';
export const PAYMENT_SYSTEM = 'PAYMENT_SYSTEM';
export const PAYMENT_SYSTEM_LABEL = 'По платежным системам';
export const CHEQUE_WRITING_TYPE = 'CHEQUE_WRITING_TYPE';
export const CHEQUE_WRITING_TYPE_LABEL = 'По способу оформления';
export const CHEQUE_WRITING_AND_FTS_SENT_DELTA_TIME =
  'CHEQUE_WRITING_AND_FTS_SENT_DELTA_TIME';
export const CHEQUE_WRITING_AND_FTS_SENT_DELTA_TIME_LABEL =
  'По времени между оформлением чека Tax Free и его доставкой в ФТС';
export const FTS_SENT_AND_VALIDATION_DELTA_TIME =
  'FTS_SENT_AND_VALIDATION_DELTA_TIME';
export const FTS_SENT_AND_VALIDATION_DELTA_TIME_LABEL =
  'По времени между доставкой чека Tax Free в ФТС и получением результатов валидации по нему от ФТС';
export const FTS_VALIDATION_AND_PAYMENT_DELTA_TIME =
  'FTS_VALIDATION_AND_PAYMENT_DELTA_TIME';
export const FTS_VALIDATION_AND_PAYMENT_DELTA_TIME_LABEL =
  'По времени между получением результатов валидации чека Tax Free от ФТС и выплатой компенсации по нему';
export const CHEQUE_WRITING_AND_PAYMENT_DELTA_TIME =
  'CHEQUE_WRITING_AND_PAYMENT_DELTA_TIME';
export const CHEQUE_WRITING_AND_PAYMENT_DELTA_TIME_LABEL =
  'По общему времени между оформлением чека Tax Free и выплатой компенсации по нему';
export const CHEQUE_WRITING_DURATION = 'CHEQUE_WRITING_DURATION';
export const CHEQUE_WRITING_DURATION_LABEL =
  'По продолжительности процесса оформления чека Tax Free на терминале';
export const reportOneSections = [
  {
    code: ACTUAL_VERSION,
    name: ACTUAL_VERSION_NAME,
    type: SELECT_TYPE,
  },
  { code: YEAR, name: YEAR_LABEL, type: BOUND_TYPE },
  { code: QUARTER, name: QUARTER_LABEL, type: BOUND_TYPE },
  { code: MONTH, name: MONTH_LABEL, type: BOUND_TYPE },
  { code: DAY, name: DAY_LABEL, type: BOUND_TYPE },
  {
    code: TRADING_ORGANIZATION,
    name: TRADING_ORGANIZATION_LABEL,
    type: MULITIPLE_TYPE,
  },
  { code: CHEQUE_STATUS, name: CHEQUE_STATUS_LABEL, type: MULITIPLE_TYPE },
  { code: CITIZENSHIP, name: CITIZENSHIP_LABEL, type: MULITIPLE_TYPE },
  { code: PAYMENT_SYSTEM, name: PAYMENT_SYSTEM_LABEL, type: MULITIPLE_TYPE },
  {
    code: CHEQUE_WRITING_TYPE,
    name: CHEQUE_WRITING_TYPE_LABEL,
    type: SELECT_TYPE,
  },
  {
    code: CHEQUE_WRITING_AND_FTS_SENT_DELTA_TIME,
    name: CHEQUE_WRITING_AND_FTS_SENT_DELTA_TIME_LABEL,
    type: BOUND_TYPE,
  },
  {
    code: FTS_SENT_AND_VALIDATION_DELTA_TIME,
    name: FTS_SENT_AND_VALIDATION_DELTA_TIME_LABEL,
    type: BOUND_TYPE,
  },
  {
    code: FTS_VALIDATION_AND_PAYMENT_DELTA_TIME,
    name: FTS_VALIDATION_AND_PAYMENT_DELTA_TIME_LABEL,
    type: BOUND_TYPE,
  },
  {
    code: CHEQUE_WRITING_AND_PAYMENT_DELTA_TIME,
    name: CHEQUE_WRITING_AND_PAYMENT_DELTA_TIME_LABEL,
    type: BOUND_TYPE,
  },
  {
    code: CHEQUE_WRITING_DURATION,
    name: CHEQUE_WRITING_DURATION_LABEL,
    type: BOUND_TYPE,
  },
];

export const NAME = 'NAME';
export const NAME_LABEL = 'По наименованию товара';
export const INPUT_METHOD = 'INPUT_METHOD';
export const INPUT_METHOD_LABEL = 'По способу ввода кассового чека ';
export const FISCAL_DATA_SOURCE = 'FISCAL_DATA_SOURCE';
export const FISCAL_DATA_SOURCE_LABEL =
  'По источнику фискальных данных кассового чека';
export const reportTwoSections = [
  { code: NAME, name: NAME_LABEL, type: MULITIPLE_TYPE },
  { code: YEAR, name: YEAR_LABEL, type: BOUND_TYPE },
  { code: QUARTER, name: QUARTER_LABEL, type: BOUND_TYPE },
  { code: MONTH, name: MONTH_LABEL, type: BOUND_TYPE },
  { code: DAY, name: DAY_LABEL, type: BOUND_TYPE },
  {
    code: TRADING_ORGANIZATION,
    name: TRADING_ORGANIZATION_LABEL,
    type: MULITIPLE_TYPE,
  },
  { code: CHEQUE_STATUS, name: CHEQUE_STATUS_LABEL, type: MULITIPLE_TYPE },
  {
    code: INPUT_METHOD,
    name: INPUT_METHOD_LABEL,
    type: SELECT_TYPE,
  },
  {
    code: FISCAL_DATA_SOURCE,
    name: FISCAL_DATA_SOURCE_LABEL,
    type: MULITIPLE_TYPE,
  },
];

export const reportThreeSections = [
  { code: YEAR, name: YEAR_LABEL, type: BOUND_TYPE },
  { code: QUARTER, name: QUARTER_LABEL, type: BOUND_TYPE },
  { code: MONTH, name: MONTH_LABEL, type: BOUND_TYPE },
  { code: DAY, name: DAY_LABEL, type: BOUND_TYPE },
  { code: COUNTRY, name: BY_COUNTRY, type: MULITIPLE_TYPE },
];

export const CHEQUE_COMMODITY_CUSTOMS_DECISIONS = [
  {
    code: 'CONFIRMED',
    name: 'Вывоз подтвержден',
  },
  {
    code: 'CONFIRMED_PARTLY',
    name: 'Вывоз подтвержден частично',
  },
  {
    code: 'WRONG_DATA',
    name: 'Вывоз не подтвержден',
  },
  {
    code: 'FORBIDDEN',
    name: 'Товары запрещены к вывозу',
  },
];

export const FIRST = 'FIRST';
export const CURRENT = 'CURRENT';

export const chequeCorrection = [
  { code: FIRST, name: 'без изменений' },
  { code: CURRENT, name: 'новая версия чека' },
];

export const ALL_STATUSES = 'ALL_STATUSES';
export const ALL_STATUSES_EN = 'All statuses';
export const ALL_STATUSES_RU = 'Все статусы';
export const ALL_STATUSES_UZ = 'Barcha maqomlar';
export const ALL_STATUSES_ZH = '全部状态';
export const ALL_STATUSES_CLASSNAME = 'all_statuses';

export const WAIT_VALIDATION = 'WAIT_VALIDATION';
export const WAIT_VALIDATION_RU = 'Ожидание подтверждения';
export const WAIT_VALIDATION_EN = 'Confirmation pending';
export const WAIT_VALIDATION_ZH = '待确认';
export const WAIT_VALIDATION_CLASSNAME = 'validation_pending';

export const WAIT_PAYMENT = 'WAIT_PAYMENT';
export const WAIT_PAYMENT_RU = 'Ожидание возмещения';
export const WAIT_PAYMENT_EN = 'Refund pending';
export const WAIT_PAYMENT_ZH = '待补偿';
export const WAIT_PAYMENT_CLASSNAME = 'refund_pending';

export const PAID = 'PAID';
export const PAID_RU = 'Возмещено';
export const PAID_RU_PLURAL = 'Возмещены';
export const PAID_EN = 'Refunded';
export const PAID_ZH = '已补偿';
export const PAID_CLASSNAME = 'refunded';
export const INVALID = 'INVALID';
export const INVALID_RU = 'Недействительный';
export const INVALID_EN = 'Invalid';
export const INVALID_ZH = '无效';
export const INVALID_CLASSNAME = 'invalid';
export const reportsChequeStatuses = [
  { code: ALL_STATUSES, name: ALL_STATUSES_RU },
  { code: WAIT_VALIDATION, name: WAIT_VALIDATION_RU, orderId: 2 },
  { code: WAIT_PAYMENT, name: WAIT_PAYMENT_RU, orderId: 3 },
  { code: PAID, name: PAID, orderId: 4 },
  { code: INVALID, name: 'Недействительные', orderId: 5 },
  {
    code: 'FTS_VALIDATION_FAILED',
    name: 'Недействительные по итогам валидации',
    orderId: 6,
  },
  {
    code: 'EXPORT_DATE_EXPIRED',
    name: 'Недействительные по срокам вывоза',
    orderId: 7,
  },
  {
    code: 'FNS_CHECKING_FAILED',
    name: 'Недействительные по данным ФНС',
    orderId: 8,
  },
  {
    code: 'PURCHASE_RETURN',
    name: 'Недействительные по возврату товара',
    orderId: 9,
  },
  {
    code: 'PAYMENT_DATE_EXPIRED',
    name: 'Недействительные по срокам выплаты',
    orderId: 10,
  },
];

export const WAIT_VALIDATION_LABEL = 'Ожидают валидацию';
export const WAIT_PAYMENT_LABEL = 'Ожидают выплату';
export const INVALID_LABEL = 'Невалидные';
export const chequeStatuses = [
  {
    code: ALL_STATUSES,
    name: ALL_STATUSES_RU,
    className: ALL_STATUSES_CLASSNAME,
  },
  {
    code: WAIT_VALIDATION,
    name: WAIT_VALIDATION_LABEL,
    className: WAIT_VALIDATION_CLASSNAME,
  },
  {
    code: WAIT_PAYMENT,
    name: WAIT_PAYMENT_LABEL,
    className: WAIT_PAYMENT_CLASSNAME,
  },
  { code: PAID, name: 'Успешные', className: PAID_CLASSNAME },
  { code: INVALID, name: INVALID_LABEL, className: INVALID_CLASSNAME },
];

export const chequeFTSStatuses = [
  { code: ALL_STATUSES, name: ALL_STATUSES_RU },
  { code: WAIT_VALIDATION, name: WAIT_VALIDATION_LABEL },
  { code: WAIT_PAYMENT, name: WAIT_PAYMENT_LABEL },
  { code: PAID, name: 'Получена выплата' },
  { code: 'FINAL', name: 'Платеж проведен' },
  { code: INVALID, name: INVALID_LABEL },
];

export const PROFILE = 'Profile';
export const VISA = 'VISA';
export const MASTERCARD_LABEL = 'MasterCard';
export const UNIONPAY_LABEL = 'UnionPay';
export const MIR_LABEL = 'MIR';
export const OTHER_LABEL = 'OTHER';

export const FTS_STATUSES = [WAIT_VALIDATION, WAIT_PAYMENT, INVALID];

export const paymentSystems = [
  { code: VISA, name: VISA, src: '../../assets/img/visa.svg' },
  {
    code: 'MASTERCARD',
    name: 'Mastercard',
    src: '../../assets/img/master-card.svg',
  },
  {
    code: 'UNIONPAY',
    name: UNIONPAY_LABEL,
    src: '../../assets/img/union-pay.svg',
  },
  // { code: 'WECHAT', name: 'Wechat', src: '../../assets/img/wechat.svg' },
  // { code: 'JCB', name: 'JCB', src: '../../assets/img/jcb.svg' },
  // { code: 'AMEX', name: 'AMEX', src: '../../assets/img/amex.svg' },
  { code: MIR_LABEL, name: MIR_LABEL, src: '../../assets/img/mir.svg' },
  { code: OTHER_LABEL, name: OTHER_LABEL, src: '../../assets/img/other.svg' },
];

export const WECHAT_PAY = 'WeChatPay';
export const CARD = 'Card';
export const CARD_RU = 'Карта';

export const PAYMENT_SYSTEMS = {
  VISA,
  MASTERCARD: MASTERCARD_LABEL,
  UNIONPAY: UNIONPAY_LABEL,
  WECHAT: WECHAT_PAY,
  JCB: 'JCB',
  AMEX: 'AMEX',
  MIR: MIR_LABEL,
  OTHER: OTHER_LABEL,
};

export const PORTAL = 'PORTAL';

export const registrationMethod = [
  { code: 'TERMINAL', name: 'Терминал' },
  { code: 'MOBILE_APP', name: 'Мобильное приложение' },
  { code: PORTAL, name: 'Портал' },
];

export const NOT_ACTUAL = 'NOT_ACTUAL';
export const ACTUAL = 'ACTUAL';

export const actualitysSource = [
  { code: NOT_ACTUAL, name: 'Неактуальные' },
  { code: ACTUAL, name: 'Актуальные' },
];

export const AUTO = 'AUTO';
export const MANUAL = 'MANUAL';
export const inputMethod = [
  { code: AUTO, name: 'Автоматически' },
  { code: MANUAL, name: 'Вручную' },
];

export const AUTOMATIC_RU = 'Автоматический';
export const MANUAL_RU = 'Ручной';

export const PAYMENT_WAITING = 'В ожидании оплаты';
export const PAYMENT_IN_PROCESS = 'Платеж в ходе выполнения';
export const PAYMENT_REJECTED = 'Платеж отклонен';
export const PAYMENT_SUSPENDED = 'Платеж отложен';
export const PAYMENT_PAID = 'Платеж успешно выполнен';

export const NO_GATEWAY = 'Оплата не проводилась';
export const MANUAL_GATEWAY = 'Ручная выплата';
export const RUS_STANDART_GATEWAY = 'Банк Русский Стандарт';
export const MONETKA_GATEWAY = 'Монетка';
export const PAYMASTER_GATEWAY = 'Paymaster';

export const paymentMethods = [
  { code: AUTO, name: AUTOMATIC_RU },
  { code: MANUAL, name: MANUAL_RU },
];

export const KKT = 'KKT';
export const FNS = 'FNS';

export const fiscalSource = [
  { code: 'US_TRADING_ORGANIZATION', name: 'УС ОРТ' },
  { code: KKT, name: KKT },
  { code: FNS, name: 'ФНС' },
];

export const CURRENCY = 'CURRENCY';

export const FiltersName = new Map<string, string>([
  [ACTUAL_VERSION, ACTUAL_VERSION_NAME],
  [YEAR, YEAR_LABEL],
  [QUARTER, QUARTER_LABEL],
  [MONTH, MONTH_LABEL],
  [DAY, DAY_LABEL],
  [TRADING_ORGANIZATION, TRADING_ORGANIZATION_LABEL],
  [CHEQUE_STATUS, CHEQUE_STATUS_LABEL],
  [CITIZENSHIP, CITIZENSHIP_LABEL],
  [PAYMENT_SYSTEM, PAYMENT_SYSTEM_LABEL],
  [CURRENCY, 'По валютам выплаты компенсации'],
  [CHEQUE_WRITING_TYPE, CHEQUE_WRITING_TYPE_LABEL],
  [UNDELIVERED_PERCENT, 'НЕОБХОДИМЫЙ ПРОЦЕНТ'],
  [
    CHEQUE_WRITING_AND_FTS_SENT_DELTA_TIME,
    CHEQUE_WRITING_AND_FTS_SENT_DELTA_TIME_LABEL,
  ],
  [
    FTS_SENT_AND_VALIDATION_DELTA_TIME,
    FTS_SENT_AND_VALIDATION_DELTA_TIME_LABEL,
  ],
  [
    FTS_VALIDATION_AND_PAYMENT_DELTA_TIME,
    FTS_VALIDATION_AND_PAYMENT_DELTA_TIME_LABEL,
  ],
  [
    CHEQUE_WRITING_AND_PAYMENT_DELTA_TIME,
    CHEQUE_WRITING_AND_PAYMENT_DELTA_TIME_LABEL,
  ],
  [CHEQUE_WRITING_DURATION, CHEQUE_WRITING_DURATION_LABEL],
  [NAME, NAME_LABEL],
  [INPUT_METHOD, INPUT_METHOD_LABEL],
  [FISCAL_DATA_SOURCE, FISCAL_DATA_SOURCE_LABEL],
  [COUNTRY, BY_COUNTRY],
]);

export const quarters = [
  { code: 'Q1', name: '1 квартал' },
  { code: 'Q2', name: '2 квартал' },
  { code: 'Q3', name: '3 квартал' },
  { code: 'Q4', name: '4 квартал' },
];

export const months = [
  { code: '01', name: 'Январь' },
  { code: '02', name: 'Февраль' },
  { code: '03', name: 'Март' },
  { code: '04', name: 'Апрель' },
  { code: '05', name: 'Май' },
  { code: '06', name: 'Июнь' },
  { code: '07', name: 'Июль' },
  { code: '08', name: 'Август' },
  { code: '09', name: 'Сентябрь' },
  { code: '10', name: 'Октябрь' },
  { code: '11', name: 'Ноябрь' },
  { code: '12', name: 'Декабрь' },
];

export const AIRPORT = 'AIRPORT';
export const AIRPORT_LABEL = 'Аэропорт';
export const AUTOMOBILE = 'AUTOMOBILE';
export const AUTOMOBILE_LABEL = 'Автомобильный';
export const SEAPORT = 'SEAPORT';
export const SEAPORT_LABEL = 'Морской';
export const MIXED = 'MIXED';
export const MIXED_LABEL = 'Смешанный';
export const ALL = 'ALL';
export const ALL_CHECKPOINTS = 'Все типы пунктов';
export const CHECKPOINT_TYPES = [
  {
    icon: '../../../../../../assets/icons/aerial-min.svg',
    value: AIRPORT,
    label: AIRPORT_LABEL,
  },
  {
    icon: '../../../../../../assets/icons/auto-min.svg',
    value: AUTOMOBILE,
    label: AUTOMOBILE_LABEL,
  },
  {
    icon: '../../../../../../assets/icons/sea-min.svg',
    value: SEAPORT,
    label: SEAPORT_LABEL,
  },
  {
    icon: '../../../../../../assets/icons/mixed-min.svg',
    value: MIXED,
    label: MIXED_LABEL,
  },
  {
    value: ALL,
    label: ALL_CHECKPOINTS,
  },
];

export const MARKER_TYPES = {
  AIRPORT: 'assets/icons/aerial-max.svg',
  AUTOMOBILE: 'assets/icons/auto-max.svg',
  SEAPORT: 'assets/icons/sea-max.svg',
};

export const MARKER_TYPES_ICONS = {
  AIRPORT: 'assets/icons/icon-aero.svg',
  AUTOMOBILE: 'assets/icons/icon-automobile.svg',
  SEAPORT: 'assets/icons/icon-sea.svg',
};
export const ALL_OFFERS = 'Все предложения';
export const ACTUAL_OFFERS = 'Только актуальные';
export const offersStatuses = [
  {
    code: ALL,
    name: ALL_OFFERS,
  },
  {
    code: ACTUAL,
    name: ACTUAL_OFFERS,
  },
];

export const operatorOffersByActive = [
  {
    label: ALL_OFFERS,
    value: false,
  },
  {
    label: ACTUAL_OFFERS,
    value: true,
  },
];

export const DESC = 'DESC';
export const ASC = 'ASC';

export const sortOrder = {
  DESC: {
    name: DESC,
    icon: '../../assets/icons/sort-desc-icon.svg',
  },
  ASC: {
    name: ASC,
    icon: '../../assets/icons/sort-asc-icon.svg',
  },
};

export const sortType = {
  issuedDate: 'issuedDate',
  maximumDate: 'maximumDate',
  paymentDate: 'paymentDate',
  refundSum: 'refundSum',
  purchase: 'purchase',
  createdAt: 'createdAt',
  chequeId: 'chequeId',
  organizationName: 'organizationName',
  customerSurname: 'customerSurname',
  tradingNetworkLegalName: 'tradingNetworkLegalName',
  ftsValidationReceivedAt: 'ftsValidationReceivedAt',
  paymentType: 'paymentType',
  paymentMethod: 'paymentMethod',
  paymentGateway: 'paymentGateway',
  paymentStatus: 'paymentStatus',
};
export const menuType = {
  TOP: 'top',
  SHOPPERS: 'shoppers',
};

export const EXPIRATION_DATE_LABEL = 'Expiration Date';
export const DATE = 'date';
export const PRICE_LABEL = 'Price';
export const offersFilters = [
  {
    code: DATE,
    name: EXPIRATION_DATE_LABEL,
  },
  {
    code: 'price',
    name: PRICE_LABEL,
  },
  {
    code: 'refund',
    name: 'Refund Amount',
  },
];

export const VAT_VALUES = [
  { label: 'НДС 20%', value: 0.2, partner: 'VAT_20', grLabel: '20' },
  { label: 'НДС 10%', value: 0.1, partner: 'VAT_10', grLabel: '10' },
  { label: 'НДС 0%', value: 0, partner: 'VAT_0', grLabel: '0' },
  { label: 'Без НДС', value: 'VAT_NONE', partner: 'VAT_NONE', grLabel: '0' },
];

export const LONG_HANDLING_TEXT =
  'Обработка запроса выполняется дольше обычного';

export const organizationInterfaces = [
  {
    name: 'ЦУМ',
  },
  {
    name: 'НЕТ_ИНТЕГРАЦИИ',
  },
];
export const EXPIRATION_DATE = 'EXPIRATION_DATE';
export const REFUND_AMOUNT_LABEL = 'Refund amount';
export const REFUND_AMOUNT_VALUE = 'SUM_VAT';

export const SORT_OPTIONS = [
  {
    label: EXPIRATION_DATE_LABEL,
    value: EXPIRATION_DATE,
    lang: {
      en: 'Valid until',
      ru: 'Актуально до',
      uz: 'Gacha haqiqiy',
      zh: '有效期至',
    },
  },
  {
    label: PRICE_LABEL,
    value: 'PRICE',
    lang: {
      en: PRICE_LABEL,
      ru: 'Цена',
      uz: 'Narxi',
      zh: '价格',
    },
  },
  {
    label: REFUND_AMOUNT_LABEL,
    value: REFUND_AMOUNT_VALUE,
    lang: {
      en: REFUND_AMOUNT_LABEL,
      ru: 'Сумма возмещения',
      uz: 'Qaytarish miqdori',
      zh: '退款额',
    },
  },
];

export const unitsOfMeasurersTypes = [
  {
    name: 'Все единицы измерения',
    value: ALL,
  },
  {
    name: 'Только не учтенные',
    value: 'CUSTOM',
  },
];

export const ACTIVE = 'ACTIVE';
export const NONACTIVE = 'NONACTIVE';

export const CURRENCY_STATUSES = [
  {
    label: ALL_STATUSES_RU,
    value: ALL,
  },
  {
    label: 'Только активные',
    value: ACTIVE,
  },
  {
    label: 'Только неактивные',
    value: 'NONACTIVE',
  },
];

export const KKT_SORTING_TYPES = {
  REG_NUM: 'REG_NUM',
  FS_NUM: 'FS_NUM',
  MANUFACTURER: 'MANUFACTURER',
  MODEL: 'MODEL',
  START_DT: 'START_DT',
  END_DT: 'END_DT',
};
export const CHEQUE_FILL_STATUSES = [
  {
    name: AUTOMATIC_RU,
    value: AUTO,
  },
  {
    name: MANUAL_RU,
    value: MANUAL,
  },
  {
    name: 'Ожидается',
    value: 'VALIDATION_REQUIRED',
  },
  {
    name: 'Положительный',
    value: 'VALID_OR_NOT_REQUIRED',
  },
  {
    name: 'Отрицательный',
    value: 'NOT_VALID',
  },
];

export const ABOUT_PAGE = 'about';
export const COOPERATION_PAGE = 'cooperation';
export const CONTACT_PAGE = 'contact';
export const JOIN_PAGE = 'join';
export const TERMS_PAGE = 'terms';
export const INSTRUCTIONS_PAGE = 'instructions';
export const CONNECTION_PAGE = 'connection';
export const SELF_CONNECTION_PAGE = 'self-connection';
export const EULA_PAGE = 'eula';
export const EULA_PAGE_TITLE = {
  en: 'License agreement',
  ru: 'Лицензионное соглашение',
  uz: 'Lisenziya shartnomasi',
  zh: '许可协议',
};
export const RECALL_PD_CONSENT_PAGE = 'recall-pd-consent';
export const RECALL_PD_CONSENT_PAGE_TITLE = {
  en: 'Recall of consent to PD processing',
  ru: 'Отзыв согласия на обработку персональных данных',
  uz: 'ShM ni qayta ishlashga rozilikni chaqirib olish',
  zh: '撤回对个人数据处理的同意',
};

export const PAGE_CODES = [
  {
    name: ABOUT_PAGE,
    value: ABOUT_PAGE,
  },
  {
    name: COOPERATION_PAGE,
    value: COOPERATION_PAGE,
  },
  {
    name: CONTACT_PAGE,
    value: CONTACT_PAGE,
  },
  {
    name: JOIN_PAGE,
    value: JOIN_PAGE,
  },
  {
    name: TERMS_PAGE,
    value: TERMS_PAGE,
  },
  {
    name: INSTRUCTIONS_PAGE,
    value: INSTRUCTIONS_PAGE,
  },
  {
    name: CONNECTION_PAGE,
    value: CONNECTION_PAGE,
  },
  {
    name: EULA_PAGE,
    value: EULA_PAGE,
  },
  {
    name: RECALL_PD_CONSENT_PAGE,
    value: RECALL_PD_CONSENT_PAGE,
  },
];
export const UPLOAD_FILE_TYPES = {
  TRADE_NET_LOGO: 'TRADE_NET_LOGO',
  TRADE_OFFER_IMG: 'TRADE_OFFER_IMG',
  CHECKPOINT_MAP: 'CHECKPOINT_MAP',
  CHECKPOINT_IMG: 'CHECKPOINT_IMG',
  TRADE_ORG_LOGO: 'TRADE_ORG_LOGO',
  CONTENT_PAGE: 'CONTENT_PAGE',
};

export const confirm = {
  COUNT_FIELD_CONFIRM_BY_PHONE: 4,
};

export const paymentSortTypes = {
  departuredDate: 'departuredDate',
  arrivedDate: 'arrivedDate',
  active: 'active',
  payment: 'payment',
  // eslint-disable-next-line id-denylist
  number: 'number',
};

export const WAIT_RESULT = 'WAIT_RESULT';
export const SUCCESS = 'SUCCESS';
export const PROCESSING_ERROR = 'PROCESSING_ERROR';
export const VALIDATED = 'VALIDATED';

export const FTS_CHEQUE_STATUSES = [
  { label: ALL_STATUSES_RU, value: ALL_STATUSES },
  { label: 'Ожидается квитанция', value: WAIT_RESULT },
  { label: 'Отправлено', value: SUCCESS },
  { label: 'Ошибка при обработке', value: PROCESSING_ERROR },
  { label: 'Обработано', value: VALIDATED },
];

export const FTS_CHEQUE_SORT_OPTIONS = {
  chequeIdNumber: 'CHEQUE_ID_NUMBER',
  ftsRequestSentAt: 'FTS_REQUEST_SENT_AT',
  ftsResponseReceivedAt: 'FTS_RESPONSE_RECEIVED_AT',
  ftsValidationReceivedAt: 'FTS_VALIDATION_RECEIVED_AT',
};

export const breadCrumbsTitles = {
  traveller: {
    ru: FOR_TRAVELLERS_RU,
    uz: FOR_TRAVELLERS_UZ,
    en: FOR_TRAVELLERS_EN,
    zh: FOR_TRAVELLERS_ZH,
  },
  business: {
    ru: FOR_BUSINESS_RU,
    uz: FOR_BUSINESS_UZ,
    en: FOR_BUSINESS_EN,
    zh: FOR_BUSINESS_ZH,
  },
  about: {
    ru: ABOUT_US_RU,
    uz: ABOUT_US_UZ,
    en: ABOUT_US_EN,
    zh: ABOUT_US_ZH,
  },
  profile: {
    ru: 'Личный кабинет',
    uz: 'Shaxsiy kabinet',
    en: 'Personal account',
    zh: '个人账户',
  },
};

export const CHECKPOINTS_ORDER = {
  AUTOMOBILE: 0,
  AIRPORT: 1,
  SEAPORT: 2,
};

export const FTS_VALIDATION_STATUS = {
  status: VALIDATED,
  sort: {
    sortOrder: ASC,
    sortType: null,
  },
};

export const NOT_FOUND_ITEMS_LOCALIZED_TEXT = {
  en: 'Nothing found',
  ru: 'Ничего не найдено',
  uz: 'Hech narsa topilmadi',
  zh: '未找到',
};

export const OFFERS_RU = 'Предложения';
export const OFFERS = 'offers';
export const SETTINGS_RU = 'Настройки';
export const SETTINGS = 'settings';
export const PARTNER_TABS = [
  {
    name: OFFERS_RU,
    routePath: OFFERS,
  },
  {
    name: SETTINGS_RU,
    routePath: SETTINGS,
  },
];
export const REFUND_TRACKER_SECTION_TITLES = {
  'By Tax Free form ID': {
    en: 'Using Tax Free form number',
    ru: 'По номеру чека Tax Free',
    uz: 'Tax Free chekining raqami bo\'yicha',
    zh: '用退税单号码',
    routePath: 'search-by-id',
  },
  'By phone number': {
    en: 'Using phone number',
    ru: 'По номеру телефона',
    uz: 'Telefon raqami bo\'yicha',
    zh: '用手机号码',
    routePath: 'by-phone-number',
  },
  'By Email': {
    en: 'Using E-mail',
    ru: 'По E-mail',
    uz: 'Elektron pochta manzili bo\'yicha',
    zh: '用电子邮件',
    routePath: 'by-email',
  },
  'By passport information': {
    en: 'Using passport',
    ru: 'По паспорту',
    uz: 'Pasporti bo\'yicha',
    zh: '用护照',
    routePath: 'by-passport-info',
  },
};

export const PASSWORD_COMPLEXITY_TOOLTIP = {
  en: 'Use only Latin letters, numbers and the following symbols: ‘!"#$%&\'()*+,-./:;<=>?@[]^_`{|}~’',
  ru: 'Вы можете использовать только латинские буквы, цифры и следующие символы: ‘!" # $% & \' () * +, -. / :; <=>? @ [] ^ _ `{|} ~’',
  uz: 'Вы можете использовать только латинские буквы, цифры и следующие символы: ‘!" # $% & \' () * +, -. / :; <=>? @ [] ^ _ `{|} ~’',
  zh: '只允许输入拉丁字母、数字和下列符号: ‘!"#$%&\'()*+,-./:;<=>?@[]^_`{|}~’',
};

export const LOCALES = [
  { label: 'Русский', value: 'ru' },
  { label: 'Английский', value: 'en' },
  { label: 'Китайский', value: 'zh' },
  { label: 'Узбекский', value: 'uz' },
];

export const CHECKS_PERIODS = [
  {
    code: '21',
    label: 'Первый квартал',
  },
  {
    code: '22',
    label: 'Второй квартал',
  },
  {
    code: '23',
    label: 'Третий квартал',
  },
  {
    code: '24',
    label: 'Четвертый квартал',
  },
  {
    code: '51',
    label: 'Первый квартал при реорганизации (ликвидации) предприятия',
  },
  {
    code: '54',
    label: 'Второй квартал при реорганизации (ликвидации) предприятия',
  },
  {
    code: '55',
    label: 'Третий квартал при реорганизации (ликвидации) предприятия',
  },
  {
    code: '56',
    label: 'Четвертый квартал при реорганизации (ликвидации) предприятия',
  },
];

export const enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export const TIMES_ZONES = [
  '-12:00',
  '-11:00',
  '-10:00',
  '-09:30',
  '-09:00',
  '-08:00',
  '-07:00',
  '-06:00',
  '-05:00',
  '-04:00',
  '-03:30',
  '-03:00',
  '-02:00',
  '-01:00',
  '00:00',
  '+01:00',
  '+02:00',
  '+03:00',
  '+03:30',
  '+04:00',
  '+04:30',
  '+05:00',
  '+05:30',
  '+05:45',
  '+06:00',
  '+06:30',
  '+07:00',
  '+08:00',
  '+08:45',
  '+09:00',
  '+09:30',
  '+10:00',
  '+10:30',
  '+11:00',
  '+12:00',
  '+12:45',
  '+13:00',
  '+14:00',
];

export const ADMIN = 'ADMIN';
export const USER = CUSTOMER;

export const USERS_ROLES = [
  {
    value: ADMIN,
    label: 'Администратор',
  },
  {
    value: PARTNER,
    label: 'Партнер',
  },
  {
    value: USER,
    label: 'Покупатель',
  },
  {
    value: OPERATOR,
    label: 'Оператор',
  },
  {
    value: PAYMENT_OPERATOR,
    label: 'Оператор платежей',
  },
];

export const ORDERED_PAYMENT_SYSTEMS = [
  {
    value: VISA,
    order: 1,
  },
  {
    value: MASTERCARD_LABEL,
    order: 2,
  },
  {
    value: UNIONPAY_LABEL,
    order: 3,
  },
  {
    value: WECHAT_PAY,
    order: 4,
  },
  {
    value: 'AliPay',
    order: 5,
  },
  {
    value: 'JCB',
    order: 6,
  },
  {
    value: 'AMEX',
    order: 7,
  },
  {
    value: MIR_LABEL,
    order: 8,
  },
  {
    value: OTHER_LABEL,
    order: 9,
  },
];

export const YES_RU = 'Да';
export const TRUE_VALUE = 'TRUE';
export const NO_RU = 'Нет';
export const FALSE_VALUE = 'FALSE';
export const DIGITAL_SIGNATURE_FILTER_VALUES = [
  {
    name: ALL_LABEL,
    value: ALL,
  },
  {
    name: YES_RU,
    value: TRUE_VALUE,
  },
  {
    name: NO_RU,
    value: FALSE_VALUE,
  },
];

export const DIGITAL_SIGNATURE_ERRORS = [
  {
    name: ALL_LABEL,
    value: '',
  },
  {
    name: 'Неверно указаны параметры подписи',
    value: 'invalid_signature_params',
  },
  {
    name: 'Указан неверный идентификатор сертификата',
    value: 'certificate_not_found_id',
  },
  {
    name: 'Указан неверный ПИН-код',
    value: 'invalid_pin',
  },
  {
    name: 'Не передан документ для подписания',
    value: 'content_required',
  },
  {
    name: 'Внутренняя ошибка сервера',
    value: 'An error has occurred',
  },
  {
    name: 'Некорректные настройки УКЭП ОРТ',
    value: 'INVALID_ORT_DIGITAL_SIGNATURE_SETTINGS',
  },
  {
    name: 'Для пользователя включено подтверждение операции входа в ЦИ с помощью вторичной аутентификации',
    value: 'invalid_grant',
  },
];

export const PAYMENT_PERMISSIONS = [
  { value: '', name: ALL_LABEL },
  { value: WITHOUT_CHECK, name: WITHOUT_CHECK_RU },
  { value: SUSPENDED, name: SUSPENDED_RU },
  { value: APPROVED, name: APPROVED_RU },
];

export const PAYMENT_METHODS = [
  { value: '', name: ALL_LABEL },
  { value: AUTO, name: AUTOMATIC_RU },
  { value: MANUAL, name: MANUAL_RU },
];

export const AWAITING_PAYMENT_ITEMS = [
  { value: '', name: ALL_LABEL },
  { value: TRUE_VALUE, name: YES_RU },
  { value: FALSE_VALUE, name: NO_RU },
];

export const OPERATION_STATUS_ITEMS = [
  { value: '', name: ALL_LABEL },
  { value: 'PENDING', name: PAYMENT_WAITING },
  { value: 'PROCESSING', name: PAYMENT_IN_PROCESS },
  { value: 'REJECTED', name: PAYMENT_REJECTED },
  { value: 'SUSPENDED', name: PAYMENT_SUSPENDED },
  { value: 'PAID', name: PAYMENT_PAID },
];

export const PAYMENT_SYSTEM_FOR_FILTER = [
  { value: '', name: ALL_LABEL },
  { value: PAYMENT_SYSTEMS.VISA, name: PAYMENT_SYSTEMS.VISA },
  { value: PAYMENT_SYSTEMS.MASTERCARD, name: MASTERCARD_LABEL },
  { value: PAYMENT_SYSTEMS.UNIONPAY, name: UNIONPAY_LABEL },
  { value: 'AliPay', name: 'AliPay' },
  { value: PAYMENT_SYSTEMS.WECHAT, name: VISA },
  { value: PAYMENT_SYSTEMS.JCB, name: PAYMENT_SYSTEMS.JCB },
  { value: PAYMENT_SYSTEMS.AMEX, name: PAYMENT_SYSTEMS.AMEX },
  { value: PAYMENT_SYSTEMS.MIR, name: PAYMENT_SYSTEMS.MIR },
  { value: 'OTHER', name: 'Другое' },
];

export const SCAN_CARD_TITLE = {
  ru: 'Сканировать карту',
  uz: 'Kartani skanerlash',
  en: 'Scan bank card',
  zh: '扫描银行卡',
};
export const SCAN_QR_TITLE = {
  ru: 'Сканировать QR код',
  uz: 'QR kodni skanerlash',
  en: 'Scan QR code',
  zh: '扫描二维码',
};
export const RECEIPT_DESCRIPTION = {
  ru: 'Отсканируйте QR код фискального чека',
  uz: 'Fiskal chekning QR kodini skanerlang',
  en: 'Scan the QR code of the receipt',
  zh: '扫描支票二维码',
};
export const CARD_DESCRIPTION = {
  ru: 'Разместите карту лицевой стороной перед камерой и держите камеру неподвижно, пока идет процесс сканирования.',
  uz: 'Kartani yuz tomoni bilan kamera oldiga joylashtiring va skanerlash jarayonida kamerani harakatsiz ushlab turing.',
  en: 'Place front side of the bank card in front of the camera and hold the camera still while the scanning process in progress.',
  zh: '请银行卡的正面放在相机前，并在扫描过程中不动地保持相机。',
};
export const WALLET_DESCRIPTION = 'Отсканируйте QR код с данными WeChat.';
export const UPLOAD_TITLE = {
  ru: 'Загрузить из файла',
  uz: 'Fayldan yuklash',
  en: 'Upload file',
  zh: '上传文件',
};
export const UPLOAD_PASSPORT_DESCRIPTION = {
  ru: 'Загрузите изображение вашего паспорта (страница с фотографией).',
  uz: 'Pasportingizning tasvirini (rasmli sahifasi) yuklang.',
  en: 'Upload an image of your passport (photo page).',
  zh: '上传护照的照片 （正面页）',
};
export const UPLOAD_CARD_DESCRIPTION = {
  ru: 'Загрузите изображение вашей карты (лицевая сторона).',
  uz: 'Kartangizning tasvirini (yuz tomoni) yuklang.',
  en: 'Upload an image of your card (front side)',
  zh: '上传银行卡的照片 （正面页）',
};
export const UPLOAD_WALLET_DESCRIPTION =
  'Загрузите изображение QR кода с данными WeChat.';
export const UPLOAD_RECEIPT_DESCRIPTION = {
  ru: 'Загрузите изображение QR кода фискального чека',
  uz: 'Fiskal chekning QR kodi tasvirini yuklang',
  en: 'Upload the receipt QR code image',
  zh: '上传支票二维码图片',
};
export const ADD_N_RECEIPTS_SUBMIT_BUTTON = {
  ru: 'Да, добавить',
  uz: 'Ha, qo\'shing',
  en: 'Yes, add',
  zh: 'Yes, add',
};
export const CARD_TITLE_ERROR = {
  ru: 'Не удалось распознать данные карты',
  uz: 'Karta ma\'lumotlarini aniqlab bo\'lmadi',
  en: 'Card recognition error',
  zh: '识别银行卡失败',
};
export const WALLET_TITLE_ERROR = 'Не удалось распознать данные WeChat';
export const RECEIPT_TITLE_ERROR = {
  ru: 'Не удалось распознать данные из QR кода',
  uz: 'QR koddagi ma\'lumotlarni aniqlab bo\'lmadi',
  en: 'QR code recognition error',
  zh: '识别二维码失败',
};
export const CARD_DESCRIPTION_ERROR = {
  ru: 'Не удалось распознать данные вашей карты, пожалуйста, попробуйте еще раз. Вы также можете выбрать скан из файла или ввести данные карты вручную.',
  uz: 'Kartangizning ma\'lumotlarini aniqlab bo\'lmadi, iltimos yana bir marta urinib ko\'ring. Shuningdek, siz faylning skanini tanlashingiz yoki karta ma\'lumotlarini qo\'lda kiritishingiz mumkin.',
  en: 'Your card data could not be recognized, please try again. You can also select a scan from the file or enter the card details manually.',
  zh: '识别您银行卡信息失败，请再试一次。您还可以选择扫描件或者手动输入信息',
};
export const WALLET_DESCRIPTION_ERROR =
  'Не удалось распознать данные WeChat, пожалуйста, попробуйте еще раз. Вы также можете выбрать скан из файла или ввести данные карты вручную.';
export const RECEIPT_DESCRIPTION_ERROR = {
  ru: 'Не удалось распознать данные фискального чека, пожалуйста, попробуйте еще раз. Вы также можете загрузить файл с изображением QR кода.',
  uz: 'Fiskal chek ma\'lumotlarini aniqlab bo\'lmadi, iltimos yana bir marta urinib ko\'ring. Shuningdek, siz QR kod tasviri bo\'lgan rasmni yuklashingiz ham mumkin.',
  en: 'Failed to recognize the data from the QR code, please try again. You can also select a scan from the file.',
  zh: '识别支票信息失败，请再试一次。您还可以选择二维码的扫描件',
};
export const WRONG_RECEIPT_TITLE_ERROR =
  'Фискальный чек не может быть добавлен';
export const WRONG_RECEIPT_DESCRIPTION_ERROR =
  'По данному фискальному чеку были возвращены товары. Компенсация НДС невозможна. Пожалуйста, используйте другой фискальный чек, за помощью вы можете обратиться в нашу службу поддержки.\n';
export const CARD_UPLOAD_DESCRIPTION_ERROR = {
  ru: 'Системе не удалось распознать данные карты в загруженном файле. Попробуйте загрузить другой файл. Вы также можете отсканировать карту с камеры или ввести данные вручную.',
  uz: 'Tizim, yuklangan fayldagi karta maʼlumotlarini aniqlay olmadi. Boshqa fayl yukashga urinib ko\'ring. Shuningdek, siz kartani kameradan skanerlashingiz yoki pasport ma\'lumotlarini qo\'lda kiritishingiz mumkin.',
  en: 'The system failed to recognize the card data in the downloaded file. Try uploading another file. You can also scan the card from the camera or enter your passport details manually.',
  zh: '我们的系统不能在上传的文件里识别银行卡信息。请上转别的文件。 您还能用手机摄像头扫描银行卡或者手动输入银行卡信息',
};
export const WALLET_UPLOAD_DESCRIPTION_ERROR =
  'Системе не удалось распознать WeChat в загруженном файле. Попробуйте загрузить другой файл. Вы также можете отсканировать QR код, используя камеру устройства, или ввести данные вручную.';
export const RECEIPT_UPLOAD_DESCRIPTION_ERROR = {
  ru: 'Системе не удалось распознать данные в загруженном файле. Попробуйте загрузить другой файл. Вы также можете отсканировать QR код при помощи камеры устройства.',
  uz: 'Tizim, yuklangan fayldagi maʼlumotlarni aniqlay olmadi. Boshqa fayl yukashga urinib ko\'ring. Shuningdek siz QR kodni qurilma kamerasi yordamida skanerlashingiz ham mumkin.',
  en: 'The system failed to recognize the data in the downloaded file. Try uploading another file. You can also scan the QR code using the device\'s camera.',
  zh: '我们的系统不能在上传的文件里识别信息。请上转别的文件。 您还能用手机摄像头扫描二维码',
};
export const SCAN_AGAIN = {
  ru: 'Сканировать еще раз',
  uz: 'Yana bir marta skanerlash',
  en: 'Scan again',
  zh: '再次扫描',
};
export const UPLOAD_AGAIN = {
  ru: 'Загрузить еще раз',
  uz: 'Yana bir marta yuklang',
  en: 'Upload again',
  zh: '再次上传',
};
export const SCAN_PASSPORT_TITLE = {
  ru: 'Сканировать паспорт',
  uz: 'Pasportni skanerlash',
  en: 'Scan passport',
  zh: '扫描护照',
};
export const PASSPORT_DESCRIPTION = {
  ru: 'Разместите страницу паспорта с фотографией перед камерой и держите камеру неподвижно, пока идет процесс сканирования.',
  uz: 'Pasportning fotosuratli sahifasini kamera oldiga joylashtiring va skanerlash jarayonida kamerani harakatsiz ushlab turing.',
  en: 'Place the passport photo page in front of the camera and hold the camera still while the scanning process in progress.',
  zh: '请护照照片页放在相机前，并在扫描过程中不动地保持相机。',
};
export const PASSPORT_TITLE_ERROR = {
  ru: 'Не удалось распознать паспортные данные',
  uz: 'Pasport ma\'lumotlarini aniqlab bo\'lmadi',
  en: 'Passport recognition error',
  zh: '识别护照信息失败',
};
export const PASSPORT_DESCRIPTION_ERROR = {
  ru: 'Не удалось распознать паспортные данные, пожалуйста, попробуйте еще раз. Вы также можете выбрать скан из файла или ввести паспортные данные вручную.',
  uz: 'Pasport ma\'lumotlarini aniqlab bo\'lmadi, iltimos yana bir marta urinib ko\'ring. Shuningdek, siz faylning skanini tanlashingiz yoki pasport ma\'lumotlarini qo\'lda kiritishingiz mumkin.',
  en: 'The passport data could not be recognized, please try again. You can also select a scan from the file or enter your passport data manually.',
  zh: '识别护照信息失败，请再试一次。您还可以选择扫描件或者手动输入信息',
};
export const PASSPORT_UPLOAD_DESCRIPTION_ERROR = {
  ru: 'Системе не удалось распознать паспортные данные в загруженном файле. Попробуйте загрузить другой файл. Вы также можете отсканировать паспорт с камеры или ввести паспортные данные вручную.',
  uz: 'Tizim, yuklangan fayldagi pasport maʼlumotlarini aniqlay olmadi. Boshqa fayl yukashga urinib ko\'ring. Shuningdek, siz pasportingizni kameradan skanerlashingiz yoki pasport ma\'lumotlarini qo\'lda kiritishingiz mumkin.',
  en: 'The system failed to recognize the passport data in the uploaded file. Try uploading another file. You can also scan your passport from the camera or enter your passport details manually.',
  zh: '我们的系统不能在上传的文件里识别护照信息。请上转别的文件。 您还能用手机摄像头扫描护照或者手动输入护照信息',
};
export const USER_DRAFT = 'user-draft';
export const ANOTHER_ORG_DRAFT = 'another-org-draft';
export const DELETED_PAYMENT_DETAILS_TITLE = {
  ru: 'Внимание',
  uz: 'Diqqat',
  en: 'Attention',
  zh: '请注意',
};
export const DELETED_PAYMENT_DETAILS_FROM_DRAFT_TEXT = {
  ru: 'Пожалуйста, укажите платежные данные для создаваемого Чека Tax Free',
  uz: 'Iltimos, yaratilayotgan Tax Free Cheki uchun to\'lov ma\'lumotlarini ko\'rsating',
  en: 'Please, enter payment details of issuring Tax Free form',
  zh: '请输入正在办理退税单的付款详情',
};
export const DELETED_PAYMENT_DETAILS_SUBMIT_BUTTON = {
  ru: 'ОК',
  uz: 'OK',
  en: 'OK',
  zh: 'OK',
};
export const NEW_RECEIPT_WARNING_MODAL_SUBMIT_BUTTON = {
  ru: 'Продолжить',
  uz: 'Davom eting',
  en: 'Continue',
  zh: '继续',
};

export const MAXIMUM = {
  ru: 'максимум',
  uz: 'ko\'pi bilan',
  en: 'maximum',
  zh: '最大',
};
export const FILE_SIZE = {
  byte: {
    ru: 'байт',
    uz: 'B',
    en: 'B',
    zh: 'B',
  },
  kb: {
    ru: 'Кб',
    uz: 'Kb',
    en: 'KB',
    zh: 'KB',
  },
  mb: {
    ru: 'Мб',
    uz: 'Mb',
    en: 'MB',
    zh: 'MB',
  },
  gb: {
    ru: 'Гб',
    uz: 'Gb',
    en: 'GB',
    zh: 'GB',
  },
  tb: {
    ru: 'Тб',
    uz: 'Tb',
    en: 'TB',
    zh: 'TB',
  },
};

/**
 * Константа, содержащая ссылки на основные пользовательские документы.
 *
 * @const
 * @type {object}
 * @default
 */
export const DOCUMENTS_URLS = {
  ru: {
    consentToPersonalDataProcessing:
      'https://mediaserver.digitax.ru/documents/consent_to_personal_data_processing_ru.pdf',
    personalDataProcessingPolicy:
      'https://mediaserver.digitax.ru/documents/0101_personal_data_processing_policy_ru.pdf',
    procedureTheSubject:
      'https://mediaserver.digitax.ru/documents/010113_exercise_of_rights_by_personal_data_subjects_ru.pdf',
    licenseAgreement:
      'https://mediaserver.digitax.ru/documents/0101_license_agreement_ru.pdf',
  },
  uz: {
    consentToPersonalDataProcessing:
      'https://mediaserver.digitax.ru/documents/consent_to_personal_data_processing_uz.pdf',
    personalDataProcessingPolicy:
      'https://mediaserver.digitax.ru/documents/0101_personal_data_processing_policy_ru_uz.pdf',
    procedureTheSubject:
      'https://mediaserver.digitax.ru/documents/010113_exercise_of_rights_by_personal_data_subjects_ru_uz.pdf',
    licenseAgreement:
      'https://mediaserver.digitax.ru/documents/0101_license_agreement_uz.pdf',
  },
  en: {
    consentToPersonalDataProcessing:
      'https://mediaserver.digitax.ru/documents/consent_to_personal_data_processing_en.pdf',
    personalDataProcessingPolicy:
      'https://mediaserver.digitax.ru/documents/0101_personal_data_processing_policy_ru_en.pdf',
    procedureTheSubject:
      'https://mediaserver.digitax.ru/documents/010113_exercise_of_rights_by_personal_data_subjects_ru_en.pdf',
    licenseAgreement:
      'https://mediaserver.digitax.ru/documents/0101_license_agreement_eng.pdf',
  },
  zh: {
    consentToPersonalDataProcessing:
      'https://mediaserver.digitax.ru/documents/consent_to_personal_data_processing_cn.pdf',
    personalDataProcessingPolicy:
      'https://mediaserver.digitax.ru/documents/0101_personal_data_processing_policy_ru_cn.pdf',
    procedureTheSubject:
      'https://mediaserver.digitax.ru/documents/010113_exercise_of_rights_by_personal_data_subjects_ru_cn.pdf',
    licenseAgreement:
      'https://mediaserver.digitax.ru/documents/0101_license_agreement_cn.pdf',
  },
};

export const EMAIL_REG_EXP = new RegExp(
  '^(([^«»‹›‘’\'♦№<>(){}\\[\\]\\\\.,;:%$!#=?\\s\\/\\|+*&\\^@"\u0080-\u2009\u2011-\u2211\u2213-\uFFFF`]+' +
    '(\\.[^«»‹›‘’\'♦№<>()\\[\\]\\\\.,;:%$!#=\\s\\/\\|+*&\\^@"\u0080-\u2010\u2011-\u2211\u2213-\uFFFF`]+)*)' +
    '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
);
export const URL_REG_EXP = new RegExp(
  '^(https?://(?:www.|(?!www))[а-яА-Яa-zA-Z0-9][а-яА-Яa-zA-Z0-9-]+[а-яА-Яa-zA-Z0-9].[^\\s]{2,}|www.' +
    '[а-яА-Яa-zA-Z0-9][а-яА-Яa-zA-Z0-9-]+[а-яА-Яa-zA-Z0-9].[^\\s]{2,}|https?://(?:www.|(?!www))[а-яА-Яa-zA-Z0-9]' +
    '+.[^\\s]{2,}|www.[а-яА-Яa-zA-Z0-9]+.[^\\s]{2,})$',
);
/**
 * Маски для номера платежного средства
 */
export const wechatMask = '0000 0000 0000 000000';
export const cardMask = '0000 0000 0000 0000';
export const otherCardMask = '0000 0000 0000 99999';
export const visaCardMask = '0000 0000 0000 0999';
export const otherFullCardMask = '00000000 00000000999';
export const mirUnionCardMask = '0000 0000 0000 00009';
export const mirUnionFullCardMask = '00000000 00000000999';
