import { Serializable } from 'src/app/core/models';
import { ALL, TRUE_VALUE } from '../..';

export class TaxFreeChequesFilter extends Serializable {
  status: string | Array<string>;
  issuedSince: Date;
  issuedTill: Date;
  period: string;
  customerSurname: string;
  tradingOrganizationName: string;
  chequeIdNumber: string;
  paymentDateFrom: Date;
  paymentDateTo: Date;
  purchaseDateFrom: Date;
  purchaseDateTo: Date;
  passportNumber: string;
  dsFilterValue: string;
  dsError: string;
  paymentPermission: string;
  awaitingManualPayment: string;
  paymentMethod: string;
  gatewayName: string;
  paymentStatus: string;
  paymentType: string;
  withRepeatedCommodities: string;

  /**
   * Преобразование в http параметры
   *
   * @returns объект в виде http params
   */
  toHttpParams(): any {
    const result = this.collectAllFields();
    if (this.paymentDateFrom) {
      result['paymentDateFrom'] = this.formatDate('paymentDateFrom');
    }
    if (this.paymentDateTo) {
      result['paymentDateTo'] = this.formatDate('paymentDateTo');
    }
    if (this.purchaseDateFrom) {
      result['purchaseDateFrom'] = this.formatDate('purchaseDateFrom');
    }
    if (this.purchaseDateTo) {
      result['purchaseDateTo'] = this.formatDate('purchaseDateTo');
    }
    return result;
  }

  /**
   * Сборка всех полей
   *
   * @returns http параметры
   */
  public collectAllFields(): any {
    const simpleFields = this.collectSimpleFields();
    const isoFields = this.collectIsoFields();
    const lowerKeyFields = this.collectLowerKeysFields();
    const booleanFields = this.collectBoolean();
    const datesFields = this.collectDates();

    return {
      ...simpleFields,
      ...isoFields,
      ...lowerKeyFields,
      ...booleanFields,
      ...datesFields
    };
  }

  /**
   * Сборка простых полей для http параметров
   *
   * @returns http параметры
   */
  collectSimpleFields() {
    const result = {};
    if (this.status) {
      result['status'] = this.status;
    }
    if (this.passportNumber) {
      result['passportNumber'] = this.passportNumber;
    }
    if (this.dsError) {
      result['digitalSignatureError'] = this.dsError;
    }
    if (this.paymentMethod) {
      result['paymentMethod'] = this.paymentMethod;
    }
    if (this.paymentPermission) {
      result['paymentPermission'] = this.paymentPermission;
    }
    if (this.gatewayName) {
      result['gatewayName'] = this.gatewayName;
    }
    if (this.paymentStatus) {
      result['paymentStatus'] = this.paymentStatus;
    }
    if (this.paymentType) {
      result['paymentType'] = this.paymentType;
    }
    return result;
  }

  /**
   * Сборка полей дат, преобразованных в ISO формат
   *
   * @returns http параметры
   */
  collectIsoFields() {
    const result = {};
    if (this.issuedSince) {
      result['issuedSince'] = this.issuedSince.toISOString();
    }
    if (this.issuedTill) {
      result['issuedTill'] = this.issuedTill.toISOString();
    }
    return result;
  }

  /**
   * Сборка полей дат
   *
   * @returns http параметры
   */
  collectDates() {
    const result = {};
    if (this.paymentDateFrom) {
      result['paymentDateFrom'] = this['paymentDateFrom'];
    }
    if (this.paymentDateTo) {
      result['paymentDateTo'] = this['paymentDateTo'];
    }
    if (this.purchaseDateFrom) {
      result['purchaseDateFrom'] = this['purchaseDateFrom'];
    }
    if (this.purchaseDateTo) {
      result['purchaseDateTo'] = this['purchaseDateTo'];
    }
    return result;
  }

  /**
   * Сборка полей, преведенных к нижнему регистру
   *
   * @returns http параметры
   */
  collectLowerKeysFields() {
    const result = {};
    if (this.customerSurname) {
      result['customerSurname'] = this.customerSurname.toLowerCase();
    }
    if (this.tradingOrganizationName) {
      result[
        'tradingOrganizationName'
      ] = this.tradingOrganizationName.toLowerCase();
    }
    if (this.chequeIdNumber) {
      result['chequeIdNumber'] = this.chequeIdNumber.toLowerCase();
    }
    return result;
  }

  /**
   * Сборка логических переменных
   *
   * @returns http параметры
   */
  collectBoolean() {
    const result = {};
    if (this.awaitingManualPayment !== '') {
      result['awaitingManualPayment'] =
        this.awaitingManualPayment === TRUE_VALUE;
    }
    if (this.withRepeatedCommodities) {
      result['withRepeatedCommodities'] = this.withRepeatedCommodities;
    }
    if (this.dsFilterValue !== ALL) {
      result['digitalSignature'] = this.dsFilterValue === TRUE_VALUE;
    }
    return result;
  }

  /**
   * Форматирование даты
   *
   * @param fieldName имя поля
   * @returns дату в формате yyyy-mm-dd
   */
  formatDate(fieldName: string) {
    const y = this[fieldName].getFullYear();
    const m =
      this[fieldName].getMonth() + 1 > 9
        ? this[fieldName].getMonth() + 1
        : `0${this[fieldName].getMonth() + 1}`;
    const d =
      this[fieldName].getDate() > 9
        ? this[fieldName].getDate()
        : `0${this[fieldName].getDate()}`;
    return `${y}-${m}-${d}`;
  }
}
